<template>
  <CRow>
    <!-- <CCol col="12" sm="6" xl="3" v-if="$_.some($store.getters.roles, role => { return ['root', 'phone_books-phone_books'].includes(role) })">
      <CWidgetIcon
        :iconPadding="false"
        color="info"
        :header="data.phone_books.toString()"
        :text="$t('phone_books')"
      >
        <CIcon name="cil-address-book" height="36"/>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          color="#ff993f"
          :width="32"
          :height="32"
        />
      </CWidgetIcon>
    </CCol>
    <CCol col="12" sm="6" xl="3" v-if="$_.some($store.getters.roles, role => { return ['root', 'phone_books-phones'].includes(role) })">
      <CWidgetIcon
        :iconPadding="false"
        color="success"
        :header="data.phones.toString()"
        :text="$t('phone_numbers')"
      >
        <CIcon name="cil-dialpad" height="36"/>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          color="#ff993f"
          :width="32"
          :height="32"
        />
      </CWidgetIcon>
    </CCol>
    <CCol col="12" sm="6" xl="3" v-if="$_.some($store.getters.roles, role => { return ['root', 'phone_books-phone_books'].includes(role) })">
      <CWidgetIcon
        :iconPadding="false"
        color="warning"
        :header="data.dial_today.toString()"
        :text="$t('dial_today')"
      >
        <CIcon name="cil-phone" height="36"/>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          color="#ff993f"
          :width="32"
          :height="32"
        />
      </CWidgetIcon>
    </CCol>
    <CCol col="12" sm="6" xl="3" v-if="$_.some($store.getters.roles, role => { return ['root', 'phone_books-phone_books', 'phone_books-phones'].includes(role) })">
      <CWidgetIcon
        :iconPadding="false"
        color="danger"
        :header="data.send_today.toString()"
        :text="$t('send_today')"
      >
        <CIcon name="cil-send" height="36"/>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          color="#ff993f"
          :width="32"
          :height="32"
        />
      </CWidgetIcon>
    </CCol>
    <CCol col="12" sm="12" v-if="$_.some($store.getters.roles, role => { return ['root', 'phone_books-phone_books', 'phone_books-phones'].includes(role) })">
      <DialStatistics />
    </CCol>
    <CCol col="12" sm="12" v-if="$_.some($store.getters.roles, role => { return ['root', 'messages'].includes(role) })">
      <SendStatistics />
    </CCol>
    <CCol col="12" sm="12" v-if="$_.some($store.getters.roles, role => { return ['root', 'phone_books-phone_books', 'phone_books-phones'].includes(role) })">
      <TagStatistics />
    </CCol> -->
  </CRow>
</template>

<script>
// import DialStatistics from './chart/DialStatistics'
// import SendStatistics from './chart/SendStatistics'
// import TagStatistics from './chart/TagStatistics'

export default {
  name: 'dashboard',
  components: {
    // DialStatistics,
    // SendStatistics,
    // TagStatistics
  },
  data () {
    return {
      data: {
        phone_books: 0,
        phones: 0,
        dial_today: 0,
        send_today: 0
      },
      searchData: { start_time: this.$moment({hour: 0}) },
      isLoading: false
    }
  },
  mounted: function () {
    // this.getDashboard()
  },
  methods: {
    getDashboard () {
      this.isLoading = true
      this.$store.dispatch('getDashboard', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.isLoading = false
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        this.isLoading = false
      })
    }
  }
}
</script>
